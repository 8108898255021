export default {
	list: [{
		desc: '德邦快递',
		code: 'DB'
	}, {
		desc: '京东快递',
		code: 'JD'
	}, {
		desc: '圆通速递',
		code: 'YT'
	}, {
		desc: '中国邮政速递物流',
		code: 'YZ'
	}, {
		desc: '极兔速递',
		code: 'JT'
	}, {
		desc: '申通快递',
		code: 'ST'
	}, {
		desc: '韵达速递',
		code: 'YD'
	}, {
		desc: '顺丰速运',
		code: 'SF'
	}, {
		desc: '中通快递',
		code: 'ZT'
	}, {
		desc: '百世快运',
		code: 'BS'
	}, {
		desc: '优速物流',
		code: 'YS'
	}, {
		desc: '其他物流',
		code: 'OT'
	}],
	getCompany(code) {
		let res = this.list.find(item => item.code == code)
		return res ? res.desc : ''
	}
}