<template>
	<div>
		<common-table
				:itemScope="['expressDelivery']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				:customHtml="true"
				ref="ct"
				:btnActionEvent="btnActionEvent"
				pageTitle="发货管理"
				@otherTableOperate="otherTableOperate"
		>
			<template v-slot:customHtml>
				<advanced-search
						:fixedConfig="fixedConfig"
						:othersConfig="othersConfig"
						@search="search"
						:hasExportFile="true"
						@exportFile="exportFile"
				/>
			</template>

			<template v-slot:expressDelivery="{ item }">
				<div class="row2">{{ ec.getCompany(item.company) }}</div>
				<div class="row2">{{ item.deliveryno }}</div>
			</template>

		</common-table>

		<template v-if="dialog">
			<exhibition-data-box
					:contentHeight="500"
					:width="936"
					:dialog.sync="dialog"
					usageMode="tabs"
					:items="items">
				<template v-if="showButtons" v-slot:footer>
					<div class="d-flex justify-space-between mt-4">
						<v-btn
							:loading="loadingLeft"
							color="redness white--text"
							depressed @click="refuse">
							拒绝退货
						</v-btn>

						<v-btn v-if="showAgree"
							:loading="loadingRight"
							color="primary white--text"
							depressed @click="agree">
							同意退货
						</v-btn>
						<v-btn v-else-if="showReceive"
							:loading="loadingRight"
							color="primary white--text"
							depressed @click="sign">
							签收退货
						</v-btn>
					</div>
				</template>
			</exhibition-data-box>

		</template>

	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/returnRequest.js';
import formatterField from '../formatter.vue';
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import detailsLog from '../common-order-js/details-log.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import gallery from "./gallery.vue"
import ec from './expressCompany.js'

export default {
	// 预约记录
	components: {advancedSearch, formatterField, exhibitionDataBox, formMenu},
	mixins: [commonCurd, detailsLog],
	data() {
		return {
			api,
			dialog: false,
			btnActionEvent: [
				{
					event: 'getDetails',
					icon: 'chakan-fill',
					color: 'primary',
					tooltip: '物流详情'
				}
			],
			headers: [
				{text: '订单号', value: 'bookingid'},
				{text: '商品信息', value: 'productdesc', width: '189px'},
				{text: '退货原因', value: 'reason'},
				{text: '退货备注', value: 'memo', width: '256px'},
				{text: '物流信息', value: 'expressDelivery'},
				{text: '审核状态', value: 'astatus'},
				{text: '申请时间', value: 'createdate'},
				{text: '操作', value: 'actions', width: '80px'}
			],
			fixedConfig: [
				{type: 'input', label: '输入订单号', property: 'bookingid'},
				{type: 'input', label: '支付流水号', property: 'payid'},
			],
			othersConfig: [
				{type: 'select', label: '审核状态', property: 'status', items: [], arrayType: false},
				{type: 'dateTime', label: '开始日期', property: 'starttime'},
				{type: 'dateTime', label: '结束日期', property: 'endttime'}
			],

			request: {
				deploy: {
					row: [
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'reason',
							connector: {
								props: {
									label: "退款原因",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refund',
							connector: {
								props: {
									label: "退款金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							default: '自行寄回',
							retain: true,
							connector: {
								props: {
									label: "退货方式",
									readonly: true
								}
							}
						},
						{
							cols: 12,
							subassembly: gallery,
							property: 'rapic'
						},
						{
							cols: 12,
							subassembly: 'VTextarea',
							property: 'returndesc',
							connector: {
								props: {
									solo: true,
									rows: 3,
									outlined: true,
									flat: true,
									placeholder: '退货描述',
									readonly: true
								}
							}
						},
						{
							cols: 12,
							subassembly: 'VTextarea',
							property: 'memo',
							connector: {
								props: {
									solo: true,
									rows: 3,
									outlined: true,
									flat: true,
									placeholder: '退货备注'
								},
								on:{
									change: (v) => {
										this.memo = v
									}
								}
							}
						},
					]
				}
			},

			order: {
				deploy: {
					row: [
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookingid',
							connector: {
								props: {
									label: "订单号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'outid',
							connector: {
								props: {
									label: "预订平台订单编号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'astatusdesc',
							connector: {
								props: {
									label: "审核状态",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'bookername',
							connector: {
								props: {
									label: "预订人姓名",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'tel',
							connector: {
								props: {
									label: "预订人手机号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "商品总金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payamount',
							connector: {
								props: {
									label: "实际支付金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payment',
							connector: {
								props: {
									label: "支付方式",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'paytime',
							connector: {
								props: {
									label: "支付时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payno',
							connector: {
								props: {
									label: "支付订单号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'payid',
							connector: {
								props: {
									label: "支付流水号",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'createdate',
							connector: {
								props: {
									label: "创建时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'debit',
							connector: {
								props: {
									label: "违约金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refund',
							connector: {
								props: {
									label: "退款金额",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'refundtime',
							connector: {
								props: {
									label: "退款时间",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'area',
							connector: {
								props: {
									label: "收货地区",
									readonly: true
								}
							}
						},
						{
							cols: 8,
							subassembly: "VTextField",
							property: 'address',
							connector: {
								props: {
									label: "收货地址",
									readonly: true
								}
							}
						},
					]
				}
			},

			logistics: {
				deploy: {
					row: [
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'company',
							connector: {
								props: {
									label: "物流公司",
									readonly: true
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'deliveryno',
							connector: {
								props: {
									label: "物流单号",
									readonly: true
								}
							}
						},
					],
				}
			},
			items: [],
			current: {},
			memo: '',
			loadingLeft: false,
			loadingRight: false,
		}
	},
	methods: {
		otherTableOperate(event, item) {

			let pr = new Promise((resolve, reject) => {
				this.axios.post(api.load, {bookingid: item.bookingid}).then(res=> {
					// console.log('pr', res, this.request.deploy.row)
					this.formatRow(res, this.request.deploy.row)
					resolve('success')
				})
			})

			let po = new Promise((resolve, reject) => {
				this.axios.post(api.order, {bookingid: item.bookingid}).then(res=> {
					// console.log('po', res.data)
					this.current = res.data
					// console.log('current', this.current)
					this.formatRow(res, this.order.deploy.row)
					resolve('success')
				})
			})

			let pl = new Promise((resolve, reject) => {
				this.axios.post(api.logistics, {bookingid: item.bookingid}).then(res=> {
					// console.log('pl', res.data)
					this.formatRow(res, this.logistics.deploy.row)
					// console.log('pl', this.logistics.deploy.row)
					resolve('success')
				})
			})

			Promise.all([pr, po, pl]).then((result) => {
				this.items = [
					{title: '申请', mainbody: {module: 'forms', binds: this.request}},
					{title: '订单', mainbody: {module: 'forms', binds: this.order}},
					{title: '物流', mainbody: {module: 'forms', binds: this.logistics}},
				]
				this.dialog = true
			})

		},

		formatRow(res, row) {
			row.forEach((e) => {
				if (!e.retain) {
					delete e.default
				}
				if (res.data[e.property] != undefined) {	// 这个undefined不能省，不然会出现：此次属性的值本来为空，但是会保留上一个次的值
					e.default = res.data[e.property]
				}
			})
		},

		refuse() {
			this.action(api.refuse, false)
		},

		agree() {
			this.action(api.agree)
		},

		sign() {
			this.action(api.sign)
		},

		action(url, right = true) {
			this.$nextTick(() => {
				right ? this.loadingRight = true : this.loadingLeft = true
				this.axios.post(url, {
					bookingid: this.current.bookingid,
					memo: this.memo
				}).then(res=> {
					right ? this.loadingRight = false : this.loadingLeft = false
					this.list()
					this.dialog = false
				})
			})
		}

	},
	mounted() {
		this.ec = ec
		this.axios.post(this.select_data, {keyname: 'auditingstatus'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.othersConfig[0].items = res.data[0].values
			}
		});
	},
	computed: {
		showButtons() {
			if (this.current.astatus == 'X' || this.current.received) {
				return false
			}
			return true
		},
		showAgree() {
			if (this.current.astatus == 'N' && !this.current.handler) {
				return true
			}
			return false
		},
		showReceive() {
			if (!this.current.received && this.logistics.deploy.row[0].default && this.logistics.deploy.row[1].default) {
				return true
			}
			return false
		}
	}
}
</script>

<style scoped lang="scss">
	.row2 {
		height: 19px;
		line-height: 19px;
	}
</style>
