export default {
	data() {
		return {
			tableProps: {
				normalPage: false,
				dialogTable: true,
				showPagination: false,
				fixedHeight: 500,
				headers: [
					{text: '操作详情', value: 'content'},
					{text: '操作人', value: 'userid'},
					{text: '操作时间', value: 'date'}
				]
			}
		}
	}
}
