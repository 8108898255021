<template>
	<div class="d-flex mt-2 mb-6">
		<div v-for="(img, index) in imgList">
			<img :src="img" class="image-small" @click="showImage(img)">
		</div>
		<v-dialog v-model="dialog" width="70%">
		    <img :src="current" alt="" style="object-fit: scale-down"/>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "mini-program-content",
	props: {
		value: {
		  type: String,
		  default: ''
		}
	},
	data() {
		return {
			imgList: [],
			dialog: false,
			current: '',
		}
	},
	methods: {
		showImage(img) {
			this.current = img
			this.dialog = true
		}
	},
	watch: {
		value: {
			handler(v) {
				if (v) {
					this.imgList = v.split(",")
				}
			},
			immediate: true
		}
	}
}
</script>

<style scoped>
	.image-small {
		width: 88px;
		height: 88px;
		border-radius: 8px;
		margin-right: 24px;
	}
</style>